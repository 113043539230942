'use client'

import NonSSRWrapper from '@/components/NonSSRWrapper'
import { Button, Input } from '@nextui-org/react'
import { useForm } from 'react-hook-form'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import { useState } from 'react'
import { Database } from '@/types/supabase'
import { joinWaitList } from '@/app/actions/joinWaitlist'

type FormData = {
    email: string
}

export default function RequestInviteCode() {
    const supabase = createClientComponentClient<Database>()
    const {
        handleSubmit,
        setError,
        formState: { errors, isLoading, isSubmitting, isValid },
        register,
        trigger,
        reset,
    } = useForm<FormData>()

    const [submitSuccess, setSubmitSuccess] = useState(false)

    const onSubmit = async (formInput: FormData) => {
        const { error } = await joinWaitList(formInput.email)

        if (!error) {
            setSubmitSuccess(true)
            reset()
        }

        const errorSplit = error?.toString().split(': ')
        setError('root.serverError', { type: error?.code, message: errorSplit && errorSplit[1] })
    }

    return (
        <NonSSRWrapper>
            <div className="flex justify-center">
                {submitSuccess ? (
                    <div className={'w-full text-center text-2xl font-semibold text-success'}>
                        Thank you -- We&apos;re excited to get you in as soon as possible!
                    </div>
                ) : (
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className={'flex flex-wrap items-center justify-center gap-6'}
                    >
                        <div className={'w-2/3'}>
                            <Input
                                type="email"
                                label="Email"
                                size={'sm'}
                                required={true}
                                description="We'll never share your email with anyone else."
                                {...register('email', {
                                    required: 'Email is Required',
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: 'Invalid email address',
                                    },
                                })}
                                isInvalid={!!errors?.email}
                                errorMessage={
                                    errors?.email?.message && <span>{errors.email.message}</span>
                                }
                                onKeyUp={() => {
                                    trigger('email')
                                }}
                            />
                            {errors.root?.serverError && (
                                <small className="bg-danger-50 text-danger">
                                    {errors.root.serverError.message}
                                </small>
                            )}
                        </div>
                        <Button
                            isLoading={isLoading || isSubmitting}
                            disabled={!isValid}
                            type={'submit'}
                            color={'secondary'}
                        >
                            <span className="text-xl font-semibold">Request Invite Code</span>
                        </Button>
                    </form>
                )}
            </div>
        </NonSSRWrapper>
    )
}
